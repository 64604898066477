/*
 * @Author: ttheitao
 * @Date: 2021-10-19 10:48:55
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-08-16 13:58:01
 * @Description: 
 */
import http from "../global/http";
import cacheHttp from "./apiStore"

const spreadsheets = {
    getList: (page, size, keyword = "") => {
        return cacheHttp({
            url: '/api/mapi',
            method: 'POST',
            data: {
                keyword: keyword,
                page: page,
                size: size,
                __method_name__: "spreadsheetList"
            }
        })
    },
    getListNoCache: (page, size, keyword = "") => {
        return http({
            url: '/api/mapi',
            method: 'POST',
            data: {
                keyword: keyword,
                page: page,
                size: size,
                __method_name__: "spreadsheetList",
                type:[1, 2, 3]
            }
        })
    },
    del: (id) => {
        return http({
            url: '/api/mapi',
            method: 'POST',
            data: {
                id: id,
                __method_name__: "spreadsheetDelete"
            }
        })
    },
    copy: (id) => {
        return http({
            url: '/api/mapi',
            method: 'POST',
            data: {
                id: id,
                __method_name__: "spreadsheetCopy"
            }
        })
    },
    detail: (id) => {
        return http({
            url: '/api/mapi',
            method: 'POST',
            data: {
                id: id,
                __method_name__: "spreadsheetDetail"
            }
        })
    },
    sync: (params, target = null) => {
        if (target?.value && target.value.originURl) {
            console.log({
                url: target.value.originURl + '/api/mapi',
                method: "POST",
                data: {
                    ...params,
                    __method_name__: "spreadsheetSync"
                },
                metadatatoken: target.value.metadatatoken
            }, '5555');
            return http({
                url: target.value.originURl + '/api/mapi',
                method: "POST",
                data: {
                    ...params,
                    __method_name__: "spreadsheetSync"
                },
                metadatatoken: target.value.metadatatoken
            })
        }
        return http({
            url: '/api/mapi',
            method: 'POST',
            data: {
                ...params,
                __method_name__: "spreadsheetSync"
            }
        })
    },
}

export default spreadsheets;