<template>
  <div
    class="custom-tree-container"
    v-loading="loading"
    element-loading-spinner="el-icon-loading"
  >
    <el-tree
      :data="data"
      node-key="id"
      style="background: #f5f5f5; padding-right: 3px;text-align: left;"
      class="py-5"
      default-expand-all
      :expand-on-click-node="false"
    >
      <div class="custom-tree-node w-full" slot-scope="{ node, data }">
        <span
          style="display: inline-block; width: calc(100% - 40px)"
          v-if="!data.operator && data.id !== 0"
        >
          <el-popover
            popper-class="pop-value-form scroller"
            :placement="value == null ? 'left' : 'right'"
            width="500"
            trigger="click"
          >
            <p class="type-label-before-blue">左侧取值</p>
            <field-select v-model="data.leftValue" :object_uuid="object_uuid"></field-select>
            <p class="type-label-before-blue mt-5">比较方式</p>
            <el-select
              clearable
              class="w-full mb-5"
              v-model="data.ruleType"
              placeholder="请选择比较值"
            >
              <el-option
                v-for="item in filterType"
                :key="'type' + item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <p class="type-label-before-blue">右侧取值</p>
            <el-input
              v-model="data.rightValue"
              placeholder="请输入右侧值"
            ></el-input>
            <div class="w-full" slot="reference" style="text-align: left">
              <span v-if="data.leftValue == null" class="col-theme"
                >左侧取值</span
              >
              <span
                v-else
                v-html="
                  fieldList.filter((v) => v.uuid == data.leftValue).length != 0
                    ? fieldList.filter((v) => v.uuid == data.leftValue)[0].name
                    : ''
                "
              ></span>
              <span v-if="data.ruleType == ''" class="col-theme mx-5"
                >比较值</span
              >
              <span
                v-else
                v-html="
                  filterType.filter((v) => v.value == data.ruleType)[0].name
                "
                class="mx-5"
              ></span>
              <span class="col-theme">{{
                data.rightValue ? data.rightValue : "无"
              }}</span>
            </div>
          </el-popover>
        </span>
        <span v-else>{{ node.label }}</span>
        <span>
          <el-popconfirm
            v-if="data.operator == ''"
            confirm-button-text="且：全部成立才可"
            cancel-button-text="或：有一个成立即可"
            icon="el-icon-info"
            icon-color="red"
            @confirm="append(data, 'and')"
            @cancel="append(data, 'or')"
            title="请选择逻辑"
          >
            <span slot="reference">
              <el-button type="primary" v-if="data.id == 0" size="mini"
                >新增</el-button
              >
              <i v-if="data.id != 0" class="el-icon-plus"></i>
              <i
                v-if="data.id != 0"
                @click="remove(node, data)"
                class="el-icon-delete ml-2"
              ></i>
            </span>
          </el-popconfirm>
          <span v-else>
            <span v-if="data.id == 0">
              <el-button
                type="primary"
                slot="reference"
                @click="append(data, data.operator)"
                size="mini"
                >新增</el-button
              >
            </span>
            <span v-else>
              <i
                @click="append(data, data.operator)"
                v-if="data.operator != ''"
                class="el-icon-plus"
              ></i>
              <i @click="remove(node, data)" class="el-icon-delete ml-2"></i>
            </span>
          </span>
        </span>
      </div>
    </el-tree>
  </div>
</template>
<script>
/* eslint-disable */
import object from "@/api/objects";
import FieldSelect from "../../../components/metaSelect/FieldSelect.vue";
import {Tree,Button, Popconfirm,Popover} from "element-ui"

let id = 1000;
export default {
  name: "ViewCondition",
  components: { FieldSelect,'el-tree' :Tree,'el-button':Button,'el-popconfirm':Popconfirm,'el-popover':Popover },

  data() {
    const data = [
      {
        id: 0,
        label: "默认",
        operator: "",
        children: [],
      },
    ];
    return {
      data: JSON.parse(JSON.stringify(data)),
      operator: "and",
      filterType: object.filterType, //筛选方式
      fieldList: [],
      loading: true,
    };
  },
  watch: {
    data: {
      handler() {
        this.$emit("input", this.data);
      },
      deep: true,
    },
    object_uuid() {
      this.initFields()
      this.data = JSON.parse(JSON.stringify([
      {
        id: 0,
        label: "默认",
        operator: "",
        children: [],
      },
    ]))
    },
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    object_uuid: {
      type: String,
      default: "",
    },
  },
  methods: {
    append(data, type) {
      if (data.operator == "") {
        data.operator = type;
      }
      let name = type == "and" ? "且：全部成立才可" : "或：有一个成立即可";
      if (data.label == "默认") {
        data.label = name;
      }
      let sid = Math.random().toString(36).substring(3);
      const newChild = {
        id: sid,
        label: "默认",
        children: [],
        operator: "",
        ruleType: "",
        leftValue: null,
        rightValue: null,
      };
      if (!data.children) {
        this.$set(data, "children", []);
      }
      data.children.push(newChild);
    },

    remove(node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex((d) => d.id === data.id);
      children.splice(index, 1);
      if (parent.data.children && parent.data.children.length == 0) {
        parent.data.label = "默认";
        parent.data.operator = "";
      }
    },
    changeData(e, name, data, node) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex((d) => d.id === data.id);
      children[index][name] = e;
    },
    initFields() {
      object
        .getFields(this.object_uuid)
        .then((res) => {
          this.loading = false;
          if (res.data.code == 200) {
            this.fieldList = res.data.data.data;
          }
        })
        .catch(() => (this.loading = false));
    },
  },
  mounted() {
    if (this.object_uuid) {
      this.initFields()
    }
    if (this.value != null && this.value.length != 0) {
      this.data = JSON.parse(JSON.stringify(this.value));
      if (
        this.value.filter &&
        this.value.filter.children &&
        this.value.filter.children.length
      ) {
        id += this.value.filter.children.length + 10;
      }
    }
  },
};
</script>

<style >
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>