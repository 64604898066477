/*
 * @LastEditTime: 2022-10-24 15:27:38
 * @Description: 聚合对象配置界面
 * @FilePath: /dataview-next/src/global/actions/aggregateObjectConfig.js
 * @Date: 2022-01-19 17:39:19
 * @Author: lisushuang
 * @LastEditors: lisushuang
 */
/* eslint-disable */

import { Alert, Button, Col, Dialog, Message, Row, Table, TableColumn } from 'element-ui';
import Vue from 'vue';
import baseAction from "./baseAction";
import ObjectSelect from "@/components/metaSelect/ObjectSelect"
import ViewSelect from "@/components/metaSelect/ViewSelect"
import FieldSelect from "@/components/metaSelect/FieldSelect"
import FormSelect from "@/components/metaSelect/FormSelect"
import ExcelSelect from "@/components/metaSelect/ExcelSelect"
import objects from "@/api/objects";
import collect from 'collect.js';
import ViewCondition from "../../views/objects/components/viewCondition"


/**
 * @description: 处理聚合对象配置的行为类
 */
export default class aggregateObjectConfig extends baseAction {

  constructor() {
    super()
  }

  init() {
    if (this.props && this.props.object_uuid) {
      this.object_uuid = this.props.object_uuid
    } else {
      this.object_uuid = null
    }
    this.objectDetail = null
    this.objectsData = []
    this.SyncOriginObjectEvent = 0
  }

  /**
   * @description: 通过uuid获取对象名称
   * @param {String} uuid 对象的uuid
   * @return {String} 对象名称
   */
  getObjectNameFromUuid(uuid = '') {
    let item = collect(this.objectsData).where('uuid', uuid).first()
    if (item) return item.name
    return '未知对象'
  }

  /**
   * @description: 通过uuid获取字段名称
   * @param {String} uuid 字段uuid
   * @return {*}
   */
  getFieldName(uuid = '') {
    if (!this.objectDetail || !this.objectDetail.fields || !this.objectDetail.fields.length) {
      return '未知字段'
    } else {
      let item = collect(this.objectDetail.fields).where('uuid', uuid).first();
      if (item) return item.name
      return '未知字段'
    }
  }

  /**
   * @description: 从 extra 中解析字段数据以及对象数据
   * @param {*}
   * @return {*}
   */
  solveExtraData() {
    let systemFields = ['created_at', 'id', 'updated_at', 'deleted_at', '__data_id__', '__object__uuid__', '__object_name__', '__object_uuid__', '__form_uuid__', '__view_uuid__', '__excel_uuid__']
    if (!this.objectDetail) return
    if (!this.objectDetail.extra) {
      this.object_list = [];
      this.fields_config = [];
    } else {
      this.fields_config = this.objectDetail.extra.aggregate_config?.fields ? this.objectDetail.extra.aggregate_config.fields : []
      this.object_list = this.objectDetail.extra.aggregate_config?.objects ? this.objectDetail.extra.aggregate_config.objects : []
      this.SyncOriginObjectEvent = this.objectDetail.extra.SyncOriginObjectEvent ? 1 : 0
    }
    this.objectDetail.fields.map((item) => {
      if (item.type !== 'append') { // 排除虚拟字段
        if (systemFields.indexOf(item.code) == -1) {
          let find = collect(this.fields_config).where('field_uuid', item.uuid).first()
          if (!find) {
            this.fields_config.push({ field_uuid: item.uuid })
          }
        }
      }
    })
  }

  handler() {
    this.getLoading()
    console.log('aggregateObjectConfig-props:', this.props)

    if (!this.object_uuid) {
      this.showError('无效的对象uuid！'); return;
    }
    objects.getOne(this.object_uuid).then(res => {
      if (res.data.code == 200) {
        this.objectDetail = res.data.data
        this.solveExtraData()
        if (!this.objectDetail.enabled || this.objectDetail.enabled != 2) {
          this.showError('非聚合对象不可配置！'); return;
        }
        objects.getAll(1, 10000, "", 0).then((res) => {
          // this.loading.close()
          if (res.data.code == 200) {
            this.objectsData = res.data.data.data;
            this.renderMain()
          } else {
            this.showError(res.data.msg)
          }
        }).catch(() => this.loading.close());
      } else {
        this.showError(res.data.msg)
      }
    }).catch(res => this.showError(res))

  }

  /**
   * @description: 主渲染方法
   */
  renderMain() {
    let _this = this
    this.mainInstance = new Vue({
      data: {
        visible: true,
        objects: _this.object_list,
        fields: _this.fields_config,
        showType: 1,
        SyncOriginObjectEvent:_this.SyncOriginObjectEvent,
        reverseColumn: true,
      },
      mounted(){
        _this.loading.close()
      },
      components:{Dialog,Alert,Row,Table,Button,TableColumn,FieldSelect,ViewCondition,FormSelect,ExcelSelect,ViewSelect,ObjectSelect},
      methods: {
        /**
         * @description: 关闭自己
         */
        closeMe() {
          this.visible = false
          _this.mainInstance.$el.remove()
        },
        /**
         * @description: 添加一个成员对象
         */
        addObjectOne() {
          this.objects.push({ object_uuid: '', form_uuid: '', view_uuid: '' ,object_uuid_alias : ''})
        },

        /**
         * @description: 设置uuid别名
         * @param {String} value 选中的uuid
         * @param {Integer} $index 正在修改的object序号
         */        
        setObjectAlias(value,$index) {
          let count = 0
          this.objects.map((item,index) => {
            if(item.object_uuid == value && index < $index){
              count ++
            }
          })
          if(count){
            this.objects[$index].object_uuid_alias = value + ',' + count
          }else{
            this.objects[$index].object_uuid_alias = value
          }
        },
        /**
         * @description: 成员对象选择列
         * @param {String} type 选择类型 [object,form,view]
         * @param {String} title 列标题
         */
        selectColumn(type = 'object', title = "") {
          return (
            <TableColumn
              label={"请选择" + title}
              align="center"
              width={type == 'view' ? '500px':'auto'}
              scopedSlots={{
                default: (scope) => {
                  return (
                    (this.objects.length && this.objects[scope.$index]) &&
                    <div>
                      {type == "object" && 
                        <ObjectSelect objectsData={_this.objectsData} v-model={this.objects[scope.$index].object_uuid} onInput={(value) => this.setObjectAlias(value,scope.$index)}></ObjectSelect>
                      }
                      {type == "view" && (this.objects[scope.$index].object_uuid
                        ? <ViewCondition v-model={this.objects[scope.$index].view_condition} object_uuid={this.objects[scope.$index].object_uuid}></ViewCondition>
                        : <span>请先选择成员对象</span>)
                      }
                      {type == "form" && (this.objects[scope.$index].object_uuid
                        ? <FormSelect v-model={this.objects[scope.$index].form_uuid} object_uuid={this.objects[scope.$index].object_uuid}></FormSelect>
                        : <span>请先选择成员对象</span>)
                      }
                      {type == "viewSelect" && (this.objects[scope.$index].object_uuid
                        ? <ViewSelect 
                            v-model={this.objects[scope.$index].view_uuid} 
                            object_uuid={this.objects[scope.$index].object_uuid}></ViewSelect>
                        : <span>请先选择成员对象</span>)
                      }
                      {type == "excelSelect" && 
                        <ExcelSelect v-model={this.objects[scope.$index].excel_uuid}></ExcelSelect>
                      }
                      {['object', 'view', 'form','excelSelect','viewSelect'].indexOf(type) == -1 && <span>非法选择列</span>}
                    </div>
                  )
                }
              }}
            ></TableColumn>
          )
        },
        /**
         * @description: 操作列
         */
        optionsColumn() {
          return (
            <TableColumn label="操作" align="center" width="100"
              scopedSlots={{
                default: (scope) => {
                  return (
                    <Button size="mini" type="danger"
                      onClick={() => this.objects.splice(scope.$index, 1)}
                    >删除</Button>
                  )
                }
              }}
            ></TableColumn>
          )
        },
        /**
         * @description: 字段列
         */
        fieldsColumn() {
          return (
            this.objects.map((item) => {
              return (
                <TableColumn min-width="150px" align="center" label={_this.getObjectNameFromUuid(item.object_uuid)} scopedSlots={{
                  default: (scope) => {
                    // todo 在后端聚合对象虚拟字段处理完成前，聚合对象配置暂时不开放虚拟字段选择
                    return (
                      <FieldSelect 
                        class={item.uuid}
                        needVirtual={true}
                        object_uuid={item.object_uuid} 
                        v-model={this.fields[scope.$index][item.object_uuid_alias]}>
                      </FieldSelect>
                    )
                  }
                }}>
                </TableColumn>
              )
            })
          )
        },
        /**
         * @description: 翻转字段列
         */
        reverseFieldsColumn() {
          return (
            this.fields.map((item) => {
              return (
                <TableColumn min-width="150px" align="center" label={_this.getFieldName(item.field_uuid)} scopedSlots={{
                  default: (scope) => {
                    // 有可能还没有设置 object_uuid_alias，先给它设置上去
                    if(!scope.row.object_uuid_alias){
                      scope.row.object_uuid_alias = scope.row.object_uuid;
                    }
                    // // todo 在后端聚合对象虚拟字段处理完成前，聚合对象配置暂时不开放虚拟字段选择
                    return (
                      <FieldSelect 
                        needVirtual={true}
                        object_uuid={scope.row.object_uuid} 
                        v-model={item[scope.row.object_uuid_alias]}
                      ></FieldSelect>
                    )
                  }
                }}>
                </TableColumn>
              )
            })
          )
        }
      },
      render: function (h) {
        return (
          <Dialog fullscreen={true} visible={this.visible} title="配置聚合对象" v-on:close={() => this.closeMe()}>
            <div style="height:calc(100vh - 140px);width:100%;display:flex;flex-direction:column">
              <Row style="margin-bottom:10px">

              <el-switch
                style="display: inline-block;margin-right:10px;user-select:none"
                value={this.SyncOriginObjectEvent}
                onInput={(value) => this.SyncOriginObjectEvent = value}
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="接收成员对象事件"
                active-value={1}
                inactive-value={0}
                inactive-text="不接收">
              </el-switch>

              <Button type="danger" onClick={() => this.showType = !this.showType}>切换{this.showType ? '字段' : '成员对象'}管理</Button>
                {this.showType ?
                  <Button type="primary" onClick={this.addObjectOne}>添加成员对象</Button>
                   : <Button type="warning" onClick={
                    () => {
                      _this.getLoading();
                      this.reverseColumn = !this.reverseColumn;
                      this.$nextTick(()=>_this.loading.close())
                    }}>翻转行列</Button>
                }
                {!this.showType ?
                  <Alert showIcon style="margin-top:10px" type="warning" effect="dark">系统字段已隐藏！created_at,updated_at,deleted_at等字段已自动处理，id 字段已在 聚合表中转换为 __data_id__ ，如无特殊需求请勿重复映射</Alert>
                  : <Alert showIcon style="margin-top:10px" type="warning" effect="dark">此处仅定义成员对象的静态条件(无取值类的条件)，动态条件请在对象的视图中定义！</Alert>
                }
              </Row>
              <Row style="flex-grow:1;overflow:hidden">
                <Table v-show={this.showType} border data={this.objects} height="100%">
                  {this.selectColumn('object', '成员对象')}
                  {this.selectColumn('view', '对象条件')}
                  {this.selectColumn('form', '对象表单')}
                  {this.selectColumn('viewSelect', '模板视图')}
                  {this.selectColumn('excelSelect', '默认模板')}
                  {this.optionsColumn()}
                </Table>
                <Table v-show={!this.showType && this.reverseColumn} border data={this.fields} height="100%">
                  <TableColumn fixed width="150px" align="center" label="字段名称" scopedSlots={{
                    default: (scope) => {
                      return (<span>{_this.getFieldName(scope.row.field_uuid)}</span>)
                    }
                  }}></TableColumn>
                  {this.fieldsColumn()}
                </Table>
                <Table v-show={!this.showType && !this.reverseColumn} border data={this.objects} height="100%">
                  <TableColumn fixed width="150px" align="center" label="对象名称" scopedSlots={{
                    default: (scope) => {
                      return (<span>{_this.getObjectNameFromUuid(scope.row.object_uuid)}</span>)
                    }
                  }}></TableColumn>
                  {this.reverseFieldsColumn()}

                </Table>
              </Row>
              {_this.cancelAndSave(h)}
            </div>
          </Dialog>
        )
      }
    }).$mount()
    this.instance.$root.$el.appendChild(this.mainInstance.$el)
  }

  /**
   * @description: 保存和取消按钮
   */
  cancelAndSave(h) {
    return (
      <Row style="flex-direction: row-reverse;margin-top:10px">
        <Button type="success" size="small" onClick={() => this.saveData()} style="margin-left:20px">保存</Button>
        <Button type="danger" size="small" onClick={() => this.mainInstance.closeMe()}>取消</Button>
      </Row>
    )
  }

  /**
   * @description: 保存配置好的数据，并关闭
   */
  saveData() {
    let data = { 
      aggregate_config: { objects: this.mainInstance.objects, fields: this.mainInstance.fields } ,
      SyncOriginObjectEvent: this.mainInstance.SyncOriginObjectEvent
    }
    if (this.objectDetail.extra.aggregate_config) {
      this.objectDetail.extra.aggregate_config = data.aggregate_config
      this.objectDetail.extra.SyncOriginObjectEvent = data.SyncOriginObjectEvent
    } else {
      this.objectDetail.extra = data
    }
    // 更新对象数据
    this.getLoading()
    objects.editOne(this.object_uuid, this.objectDetail.name, this.objectDetail.remark, this.objectDetail.extra).then(res => {
      this.loading.close()
      if (res.data.code == 200) {
        Message.success('更新聚合对象配置成功！')
        this.mainInstance.closeMe()
      } else {
        this.showError(res.data.msg)
      }
    }).catch(() => this.showError('更新配置失败！'))
  }
}
