<!--
 * @LastEditTime: 2022-08-04 14:58:14
 * @Description: luckysheet 模板选择器
 * @FilePath: /dwh_front/src/components/metaSelect/ExcelSelect.vue
 * @Date: 2022-02-17 11:30:33
 * @Author: lisushuang
 * @LastEditors: ttheitao
-->
<template>
  <el-select
    v-model="selectValue"
    filterable
    allow-create
    value-key="uuid"
    style="width: 100%"
    default-first-option
    v-loading="selectLoading"
    placeholder="请选择模板"
    ref="obselect"
    element-loading-spinner="el-icon-loading"
    @focus="onSelectFoucus = true"
  >
    <el-option
      v-for="(item, index) in realSelectData"
      :key="index"
      :label="item.name"
      :value="item.uuid"
    >
    </el-option>
  </el-select>
</template>

<script>
import spreadsheets from "@/api/spreadsheets";
import collect from "collect.js";

export default {
  name: "ExcelSelect",
  props: {
    value: {
      type: String,
      default: "",
    },
  },

  watch: {
    object_uuid() {
      this.getSelectData();
    },
    effect(val, oldVal) {
      if (val.value == oldVal.value) {
        return;
      }
      this.getSelectData();
    },
    value(val, oldVal) {
      if (val && !oldVal && !this.selectValue) {
        this.selectValue = val;
      }
    },
    selectValue(val) {
      this.$emit("input", val);
      this.$nextTick(() => {
        this.onSelectFoucus = false;
        this.$refs.obselect.blur();
      });
    },
  },
  data() {
    return {
      selectData: [],
      selectValue: "",
      selectLoading: true,
      onSelectFoucus: false,
    };
  },
  computed: {
    /**
     * @description: 实际展示的选择数据，在没有focus时只显示唯一的那一条
     * @param {*}
     * @return {*}
     */
    realSelectData() {
      if (this.onSelectFoucus) {
        return this.selectData;
      } else {
        let one = collect(this.selectData).where("uuid", this.value).first();
        if (one) return [one];
        return [];
      }
    },
  },
  methods: {
    getSelectData() {
      this.selectLoading = true;

      spreadsheets
        .getList(1,10000)
        .then((res) => {
          this.selectLoading = false;
          if (res.data.code == 200) {
            this.selectData = res.data.data.data;
          } else {
            this.selectData = [];
          }
        })
        .catch(() => {
          this.selectLoading = false;
          this.selectData = [];
        });
    },
  },
  mounted() {
    this.getSelectData();
    this.selectValue = this.value;
  },
};
</script>
