<!--
 * @LastEditTime: 2022-08-04 14:58:44
 * @Description: 对象表单选择器
 * @FilePath: /dwh_front/src/components/metaSelect/FormSelect.vue
 * @Date: 2022-01-20 15:54:53
 * @Author: lisushuang
 * @LastEditors: ttheitao
-->

<template>
  <el-select
    :multiple="multiple"
    filterable
    allow-create
    v-model="selectValue"
    style="width: 100%"
    v-loading="selectLoading"
    default-first-option
    element-loading-spinner="el-icon-loading"
  >
    <el-option
      v-for="(item, index) in selectData"
      :key="index"
      :label="item.uuid + '-' + item.name"
      :value="item.uuid"
    >
    </el-option>
  </el-select>
</template>

<script>
import objects from "@/api/objects";

export default {
  name: "FormSelect",
  props: {
    object_uuid: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    object_uuid() {
      this.getSelectData();
    },
    selectValue() {
      this.$emit("input", this.selectValue);
    },
    value(val, oldVal) {
      if (val && !oldVal && !this.selectValue) {
        this.selectValue = val;
      }
    },
  },
  data() {
    return {
      selectData: [],
      selectValue: "",
      selectLoading: true,
    };
  },
  methods: {
    getSelectData() {
      this.selectLoading = true;
      if (this.object_uuid) {
        objects.getFormList(this.object_uuid).then(res=>{
          this.selectLoading = false
          if(res.data.code == 200){
            this.selectData = res.data.data
          }
        }).catch(()=>this.selectLoading = false)
      }
    },
  },
  mounted() {
    this.getSelectData();
    this.selectValue = this.value
  },
};
</script>